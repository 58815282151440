<template>
  <v-list two-line class="pt-0">
    <v-list-item color="#365d84" style="background-color: #365d84 ;"
      dark>
      <v-list-item-avatar>
          <v-icon
            class="grey lighten-1"
            dark
          >
          mdi-information
          </v-icon>
        </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>Информация</v-list-item-title>
      </v-list-item-content>
      <v-list-item-action>
        <orgEdit v-if="!loading && $checkRole($route.params.id, 'admin')" :btn="true" :regions="regions"
          :organization="eduOrg" @update="eduOrg = $event" />
        </v-list-item-action>

    </v-list-item>

    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>Роли</v-list-item-title>
      </v-list-item-content>
      
      <v-list-item-action>
        <group type="admin" :count="rolesCount" v-if="$checkRole($route.params.id, 'admin')" class="mb-3"
          :loading="loading" />
        </v-list-item-action>
    </v-list-item>

    <v-list-item two-line>
      <v-list-item-content>
          <v-list-item-title>Короткое наименование</v-list-item-title>
          <v-list-item-subtitle>{{ eduOrg.short_name }}</v-list-item-subtitle>
        </v-list-item-content>
    </v-list-item>

    <v-list-item two-line>
      <v-list-item-content>
          <v-list-item-title>Полное наименование</v-list-item-title>
          <v-list-item-subtitle>{{ eduOrg.name }}</v-list-item-subtitle>
        </v-list-item-content>

    </v-list-item>
    <v-list-item two-line>
      <v-list-item-content>
          <v-list-item-title>ИНН</v-list-item-title>
          <v-list-item-subtitle>{{ eduOrg.inn }}</v-list-item-subtitle>
        </v-list-item-content>

    </v-list-item>
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title>ФИО руководителя</v-list-item-title>
        <v-list-item-subtitle>{{ eduOrg.director_name }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title>Номер лицензи</v-list-item-title>
        <v-list-item-subtitle> {{ eduOrg.lic_num }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title>Дата выдачи лицензии</v-list-item-title>
        <v-list-item-subtitle> {{ eduOrg.lic_date }}</v-list-item-subtitle>
      </v-list-item-content>

    </v-list-item>
  </v-list>
</template>


<script>
import orgEdit from "./edit.vue";
import group from "./group.vue";
export default {
  name: "StarterPage",
  data: () => ({
    regions: [],
    eduOrg: {},
    loading: true
  }),
  components: {
    group,
    orgEdit
  },
  watch: {
    eduOrg(val) {
      this.$emit('orgUpdate', val)
    }
  },
  methods: {
    fetchData() {
      this.loading = true
      this.$http
        .get("edu-org/info?id=" + this.$route.params.id)
        .then((response) => {
          this.eduOrg = response.data.info;
          this.regions = response.data.regions;
          this.rolesCount = response.data.rolesCount
        })
        .catch(() => {
          alert("Возникла ошибка");
        }).finally(() => {
          this.loading = false
        });
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>
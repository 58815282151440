<template>
  <v-card elevation="0" class="w-100 ma-0 rounded-0 h-100 d-flex flex-column" color="rgb(255 255 255 / 80%)">
    <v-toolbar color="rgb(90 126 161)" flat dark>
      <v-toolbar-title>Образовательные программы</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-text-field v-model="searchString" append-icon="mdi-magnify" label="Поиск" single-line
        hide-details></v-text-field>
      <newProg v-if="$checkRole($route.params.id, 'admin')" :eduOrgId="eduOrgId" @create="fetchData()" />
    </v-toolbar>
    <v-divider></v-divider>
    <v-card-text class="pa-0 h-100">
      <v-list v-if="!loading" color="transparent" class="h-100">
        <template v-for="item of list">
          <v-list-item :key="item.id" :to="'/edu-prog/view/' + item.id + '/info'">
            <template v-slot:prepend>
              <v-chip class="mr-3">{{ item.id }}</v-chip>
            </template>
            <v-list-item-content>
              <v-list-item-title>{{ item.name }}</v-list-item-title>
              <v-list-item-subtitle><b>Профиль:</b> {{ item.profile }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-avatar>
              <deleteModal v-if="$checkRole($route.params.id, 'admin')" title="Удалить"
                :text="'При удалении образовательной программы вся информация о ней будет безвозвартано потеряна, вы действительно хотите удалить образовательную программу: ' + item.name"
                :actionHref="'edu-prog/delete?eduProgId=' + item.id"
                @remove="list.splice(list.findIndex(el => el.id === item.id), 1)" />
            </v-list-item-avatar>

          </v-list-item>
          <v-divider :key="'d' + item.id" />
        </template>
        <div v-if="list.length==0" class="w-100 h-100 d-flex align-center justify-center text-h6"><div>Ничего не найдено</div></div>
        <div
          v-intersect="fetchData"
        ></div>
      </v-list>
      <div v-else class="w-100 h-100 d-flex align-center justify-center">
        <v-progress-circular 
    
      :size="60"
      :width="7"
      color="purple"
      indeterminate
    ></v-progress-circular>
      </div>


    </v-card-text>
  </v-card>
</template>

<script>
const debounce = function (f, ms) {
  let timer = null;
  return function (...args) {
    const onComplete = () => {
      f.apply(this, args);
      timer = null;
    };
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(onComplete, ms);
  };
};
import newProg from './new.vue'
import deleteModal from './delete.vue'
export default {
  props: ['eduOrgId'],
  components: {
    newProg,
    deleteModal
  },
  data: () => ({
    searchString: "",
    loading: true,
    fetching:false,
    page: 1,
    itemsPerPage: 10,
    headers: [
      { text: "ID", value: "id" },
      { text: "Название", value: "name" },
      { text: "Профиль", value: "profile" },
      { text: "Действия", value: "actions" },
    ],
    list: [],
    count: 1,
    done:false
  }),
  methods: {
    fetchData() {
      if(this.done || this.fetching){
        return
      }
      this.fetching=true
      this.$http
        .get(
          "edu-prog/list?eduOrgId=" +
          this.eduOrgId +
          "&page=" +
          this.page +
          "&perPage=" +
          this.itemsPerPage +
          "&string=" +
          this.searchString
        )
        .then((response) => {
          this.list.push(...response.data.programms);
          this.count = parseInt(response.data.count);
          this.page++
          if(response.data.programms.length==0){
            this.done=true
          }
        })
        .catch(() => {
          alert("Возникла ошибка");
        })
        .finally(() => {
          this.loading = false
          this.fetching=false
        });
    },
    search: debounce(function () {
      this.page=1
       this.list=[]
       this.done=false
       this.loading = true
      this.fetchData();
    }, 500),
  },
  watch: {
    searchString() {

      this.search();
    },
  },
  mounted() {
    this.fetchData()
  }
}
</script>

<style></style>
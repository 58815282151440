<template>
  <v-dialog v-model="dialog" fullscreen>
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" class="ml-3" dark v-bind="attrs" v-on="on">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-toolbar color="#365d84" flat dark>
        <v-toolbar-title>Добавить образовательную программу</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn :disabled="saving" @click="dialog = false" light>
          <v-icon>mdi-close</v-icon> Отменить
        </v-btn>
        <v-btn :loading="saving" color="green" class="mx-3" @click="save()">
          <v-icon>mdi-check</v-icon> Сохранить
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-row>
          <v-col cols="6">
            <v-card elevation="12">
              <v-toolbar color="#365d84" flat dark>
                <v-toolbar-title>Основная информация</v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>
              <v-divider></v-divider>
              <v-card-text>
                <v-select :items="[
                  { label: 'Высшее образование (ВО)', value: 'vo' },
                  { label: 'Среднее профессиональное образование (СПО)', value: 'spo' },
                  { label: 'Дополнительное профессиональное образование (ДПО)', value: 'dpo' },
                  { label: 'Профессиональное обучение (ПО)', value: 'po' },
                ]" v-model="form.type" :error="errors.EduProgramms.type ? true : false" :error-messages="
  errors.EduProgramms.type
    ? errors.EduProgramms.type
    : []
" outlined item-text="label" item-value="value" label="Форма обучения"></v-select>
                <v-text-field v-model="form.name" :error="errors.EduProgramms.name ? true : false" :error-messages="
                  errors.EduProgramms.name ? errors.EduProgramms.name : []
                " outlined :counter="255" :rules="rules.required" label="Наименование" required></v-text-field>

                <v-text-field v-model="form.profile" v-if="form.type=='vo' || form.type=='spo'" :error="errors.EduProgramms.profile ? true : false"
                  :error-messages="
                    errors.EduProgramms.profile
                      ? errors.EduProgramms.profile
                      : []
                  " outlined :counter="255" :rules="rules.required" label="Профиль" required></v-text-field>

                <v-text-field type="number" v-model="form.term" :error="errors.EduProgramms.term ? true : false"
                  :error-messages="
                    errors.EduProgramms.term ? errors.EduProgramms.term : []
                  " outlined :rules="rules.required"
                  label="Нормативный срок освоения, утвержденный образовательной организацией (в часах)" required>
                </v-text-field>

                <v-select :items="[
                  { label: 'Очная', value: 1 },
                  { label: 'Очно-заочная', value: 2 },
                  { label: 'Заочная', value: 3 },
                ]" v-model="form.studyForm" :error="errors.EduProgramms.studyForm ? true : false" :error-messages="
  errors.EduProgramms.studyForm
    ? errors.EduProgramms.studyForm
    : []
" outlined item-text="label" item-value="value" label="Форма обучения"></v-select>

                <v-autocomplete :items="oksoList" :loading="oksoLoading" :search-input.sync="oksoSearch" v-if="form.type=='vo' || form.type=='spo'"
                  @change="loadFgos(null, true)" no-filter hide-no-data filled hide-selected item-text="name"
                  item-value="id" v-model="form.oksoId" :error="errors.EduProgramms.oksoId ? true : false"
                  :error-messages="
                    errors.EduProgramms.oksoId ? errors.EduProgramms.oksoId : []
                  " label="Код по ОКСО">
                  <template v-slot:selection="data">
                    {{ data.item.code }} /
                    <b class="pl-1">{{ data.item.name }}</b>
                  </template>
                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.name"></v-list-item-title>
                      <v-list-item-subtitle v-html="data.item.code"></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
                <v-select v-if="form.type=='vo' || form.type=='spo'" outlined :items="[
                  { text: 'Выбрать из списка', value: false },
                  { text: 'Создать новый', value: true },
                ]" v-model="eduStandardOwnSwitch" label="Образовательный стандарт"></v-select>

                <v-autocomplete v-if="!eduStandardOwnSwitch && (form.type=='vo' || form.type=='spo')" :items="fgosList" :loading="fgosLoading" 
                  :search-input.sync="fgosSearch" hide-no-data no-filter filled hide-selected item-text="name"
                  item-value="id" @change="loadSkills($event); loadOkso(null, true)" v-model="form.eduStandId"
                  :error="errors.EduProgramms.eduStandId ? true : false" :error-messages="
                    errors.EduProgramms.eduStandId
                      ? errors.EduProgramms.eduStandId
                      : []
                  " label="ФГОС">
                  <template v-slot:selection="data">
                    {{ data.item.code }} / {{ data.item.approvalDate }} /
                    <b class="pl-1">{{ data.item.name }}</b>
                  </template>
                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.name"></v-list-item-title>
                      <v-list-item-subtitle>{{ data.item.code }} -
                        {{ data.item.approvalDate }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
                <v-select 
                  v-if="!eduStandardOwnSwitch && skills.length > 0 && (form.type=='vo' || form.type=='spo')"
                  outlined label="Основная квалификация выпускника" v-model="eduProgrammSkills.eduStandSkillId"
                  :error="errors.EduProgrammSkills.eduStandSkillId ? true : false" :error-messages="
                    errors.EduProgrammSkills.eduStandSkillId
                      ? errors.EduProgrammSkills.eduStandSkillId
                      : []
                  " hide-no-data no-data-text="Ничего не найдено" item-text="name" item-value="id"
                  :loading="skillsLoading" :items="skills">
                </v-select>
                <v-combobox v-else v-model="eduProgrammSkills.name" :error="errors.EduProgrammSkills.name ? true : false"
                  :error-messages="errors.EduProgrammSkills.name ? errors.EduProgrammSkills.name : []" :rules="rules.required"
                  :items="customSkills" label="Основная квалификация выпускника"
                >
                </v-combobox>
              </v-card-text>
            </v-card>
            <v-card elevation="12" class="mt-3" v-if="eduStandardOwnSwitch && (form.type=='vo' || form.type=='spo')">
              <v-toolbar color="#365d84" flat dark>
                <v-toolbar-title>Образовательный стандарт</v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>
              <v-divider></v-divider>
              <v-card-text>
                <v-text-field v-model="eduStandardOwn.name" :error="errors.EduStandards.name ? true : false"
                  :error-messages="
                    errors.EduStandards.name ? errors.EduStandards.name : []
                  " outlined :counter="255" :rules="rules.required" label="Название" required></v-text-field>
                <v-text-field v-model="eduStandardOwn.approvalOrder"
                  :error="errors.EduStandards.approvalOrder ? true : false" :error-messages="
                    errors.EduStandards.approvalOrder ? errors.EduStandards.approvalOrder : []
                  " outlined :counter="255" :rules="rules.required" label="Номер приказа" required></v-text-field>
                <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y
                  min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="eduStandardOwn.approvalDate"
                      :error="errors.EduStandards.approvalDate ? true : false" :error-messages="
                        errors.EduStandards.approvalDate ? errors.EduStandards.approvalDate : []
                      " label="Дата приказа" prepend-icon="mdi-calendar" :rules="rules.required" readonly
                      v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="eduStandardOwn.approvalDate" locale="ru-RU" :first-day-of-week="1">
                  </v-date-picker>
                </v-menu>

              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card elevation="12">
              <v-toolbar color="#365d84" flat dark>
                <v-toolbar-title>Структура</v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>
              <v-divider></v-divider>
              <v-card-text>
                <v-text-field type="number" v-model="form.courses" :max="10" :min="1" outlined
                  oninput="if(Number(this.value) > Number(this.max)) {this.value = this.max;} if(Number(this.value) < Number(this.min)){this.value = this.min;}"
                  :rules="rules.required" label="Количество курсов обучения" required></v-text-field>
                <v-text-field type="number" v-model="periods" outlined disabled label="Всего периодов обучения">
                </v-text-field>
                <div v-if="form.courses > 0">
                  <div v-for="course in courses.length" :key="course">
                    <v-text-field type="number" v-model="courses[course - 1]" outlined
                      oninput="if(Number(this.value) > Number(this.max)) {this.value = this.max;} if(Number(this.value) < Number(this.min)){this.value = this.min;}"
                      :max="12" :min="1" :rules="rules.required" :label="'Курс ' + course + ' - периодов обучения'"
                      required></v-text-field>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["eduOrgId"],
  data() {
    return {
      dialog: false,
      saving: false,
      oksoSearch: "",
      oksoLoading: false,
      oksoList: [],
      fgosSearch: "",
      fgosLoading: false,
      fgosList: [],
      skills: [],
      customSkills:[],
      skillsLoading: false,
      menu: false,
      eduStandardOwnSwitch: false,
      eduStandardOwn: {},
      eduProgrammSkills: {},
      form: {
        type:'vo',
        courses: 1,
      },
      courses: [2],
      rules: {
        required: [(v) => !!v || "Не должно быть пустым"],
      },
      fileRules: [
        (value) =>
          !value || value.size < 20000000 || "Максимальный размер файла 20 MB!",
      ],
      errors: {
        EduProgramms: {},
        EduStandards: {},
        EduProgrammSkills: {},
      },
    };
  },
  methods: {
    loadSkills(eduStandId) {
      if (!eduStandId) {
        this.skills = [];
        return;
      }
      this.skillsLoading = true;
      this.$http
        .get("edu-prog/skills", {
          params: {
            eduStandId: eduStandId,
          },
        })
        .then((response) => {
          this.skills = response.data.list;
          if (this.skills.length > 0) {
            this.form.skillId = this.skills[0].id
          }
          if(response.data.advanced){
            this.customSkills=response.data.advanced;
          }
        })
        .catch(() => {
          this.skills = [];
          alert("Возникла ошибка");
        })
        .finally(() => {
          this.skillsLoading = false;
        });
    },
    save() {
      this.saving = true;
      this.errors = {
        EduProgramms: {},
        EduStandards: {},
        skill: {},
        EduProgrammSkills: {},
      };
      this.$http
        .post("edu-prog/create?eduOrgId=" + this.eduOrgId, {
          EduProgramms: this.form,
          courses: this.courses,
          EduProgrammSkills: this.eduProgrammSkills,
          eduStandardOwnSwitch: this.eduStandardOwnSwitch,
          EsEduStandards: this.eduStandardOwn
        })
        .then(() => {
          this.$emit('create')
          this.dialog = false
        })
        .catch((e) => {
          if (e.response.data) {
            if (e.response.data.EduProgramms)
              this.errors.EduProgramms = e.response.data.EduProgramms;
            if (e.response.data.EsEduStandards)
              this.errors.EduStandards = e.response.data.EsEduStandards;
            if (e.response.data.EduProgrammSkills)
              this.errors.EduProgrammSkills = e.response.data.EduProgrammSkills;
          } else {
            alert("error!");
          }
        })
        .finally(() => {
          this.saving = false;
        });
    },
    checkInputNumber(val) {
      if (Number(val.value) > Number(val.max)) val.value = val.max;
    },
    loadFgos(val = null, auto = false) {
      let oksoId = null
      if (auto) {
        oksoId = this.form.oksoId
      }
      // Items have already been requested
      if (this.fgosLoading) return;

      this.fgosLoading = true;

      this.$http
        .get("edu-prog/fgos-list", {
          params: {
            eduOrgId: this.eduOrgId,
            string: val,
            oksoId: oksoId
          },
        })
        .then((response) => {
          this.fgosList = response.data.list;
          if (response.data.auto) {
            this.form.eduStandId = this.fgosList[0].id
            this.loadSkills(this.form.eduStandId)
          }
        })
        .catch(() => {
          alert("Ошибка при загрзке списка кодов по ОКСО");
        })
        .finally(() => {
          this.fgosLoading = false;
        });
    },
    loadOkso(val = null, auto = false) {
      let fgosId = null
      if (auto) {
        fgosId = this.form.eduStandId
      }
      // Items have already been requested
      if (this.oksoLoading) return;

      this.oksoLoading = true;

      this.$http
        .get("edu-prog/okso-list", {
          params: {
            string: val,
            fgosId: fgosId
          },
        })
        .then((response) => {
          this.oksoList = response.data.list;
          if (response.data.auto) {
            this.form.oksoId = this.oksoList[0].id
          }
        })
        .catch(() => {
          alert("Ошибка при загрзке списка кодов по ОКСО");
        })
        .finally(() => {
          this.oksoLoading = false;
        });
    }
  },
  computed: {
    periods: {
      cache: false,
      get() {
        let sum = 0;
        for (let course of this.courses) {
          sum += parseInt(course);
        }
        return sum;
      },
    },
  },
  watch: {
    "form.courses"() {
      this.$set(this.courses, "length", parseInt(this.form.courses));
      for (let num = 0; num < parseInt(this.form.courses); num++) {
        if (!this.courses[num]) {
          this.courses[num] = 2;
        }
      }
    },
    "form.type"(val) {
      if(val=='dpo' || val=='po'){
        this.form.eduStandId=null,
        this.form.oksoId=null,
        this.form.eduStandardOwnSwitch=false
        this.eduStandardOwn = {}
        this.eduProgrammSkills = {}
        this.customSkills = []
      }
    },
    oksoSearch(val) {
      this.loadOkso(val)
    },
    fgosSearch(val) {
      this.loadFgos(val)
    },
  },
};
</script>

<style>
</style>
<template>
  <div>
    <v-dialog v-model="dialog" width="600">
          <template v-slot:activator="{ on, attrs }">
        <v-btn
        color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2 mt-3 justify-space-between">
          Добавить пользователя
          <v-btn fab @click="dialog = false" small
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <div class="text-center pa-3">
        <v-btn-toggle v-model="tab" >
        <v-btn>
          Выбрать
        </v-btn>

        <v-btn>
          Зарегистрировать
        </v-btn>
      </v-btn-toggle>
    </div>
        <v-card-text>

          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-card flat>
                <v-card-text><userSearch :value.sync="userIdFromDb" retutnObject/></v-card-text>
                                <div class="text-right">
                  <v-btn @click="addFromDb()" color="primary" :loading="inProccess">Добавить</v-btn>
                </div>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <v-text-field label="Фамилия" v-model="form.surname" />
                  <v-text-field label="Имя" v-model="form.name" />
                  <v-text-field label="Отчество" v-model="form.patronymic" />
                  <v-text-field label="E-mail" v-model="form.email" />
                </v-card-text>
                <div class="text-right">
                  <v-btn color="primary" :loading="inProccess" @click="createAndAdd()">Добавить</v-btn>
                </div>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import userSearch from "@/components/userSearch.vue";
export default {
  props:['type', 'allowCreate'],
  data: () => ({
    tab: 0,
    dialog: false,
    form: {},
    userIdFromDb:{},
    inProccess:false,
  }),
  components: {
    userSearch,
  },
  methods: {
    addFromDb(){
      this.inProccess=true
      this.$http.get('edu-org/role-add',{
        params:{
          orgId: this.$route.params.id,
          userId: this.userIdFromDb.user_id,
          type: this.type
        }
      }).then((response)=>{
        this.dialog=false
        this.$emit('roleAdd',response.data)
      }).catch((e)=>{
        if(e.response.data){
          alert(e.response.data)
        } else {
          alert('Ошибка')
        }
      }).finally(()=>{
        this.inProccess=false
      })
    },
    createAndAdd(){
      this.inProccess=true
      this.$http.post('edu-org/add-user-with-role?orgId='+this.$route.params.id+'&type='+this.type,this.form).then((response)=>{
        this.dialog=false
        this.$emit('roleAdd',response.data)
      }).catch((e)=>{
        if(e.response.data){
          alert(e.response.data)
        } else {
          alert('Ошибка')
        }
      }).finally(()=>{
        this.inProccess=false
      })
    }
  },
};
</script>

<style>
</style>
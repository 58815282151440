<template>
  <div  class="d-flex flex-row h-100 w-100">
<div class="w-100 h-100" v-if="menu && $vuetify.breakpoint.mobile" @click="menu=false" style="    background-color: rgb(0 0 0 / 50%);position: fixed; top:0px;z-index:1"></div>
        <v-list     class="pa-0 h-100 elevation-12"  
    width="350px"
    :class="{'naviagationVisible':menu,'naviagationHidden':!menu,'naviagationMobile':$vuetify.breakpoint.mobile}">
          <v-list-item prepend-icon="mdi-arrow-left-circle" class="text-body-2" to="/edu-org/list">К списку организаций</v-list-item>
       



        <info :eduOrg.sync="eduOrg" />
  
      </v-list>


  <op :eduOrgId="$route.params.id" />


       
  

    </div>
</template>
<style>
.naviagationVisible{
  z-index:1;
  margin-left:0px;
  transition: margin-left 0.3s;
}
.naviagationHidden{
  margin-left:-350px;
  transition: margin-left 0.3s;
}
.naviagationMobile{
  position: fixed;
  overflow-y: auto;
  z-index:200 !important;
}
</style>
<script>

import info from './info.vue'
import op from '../eduProg/list.vue'
export default {
  name: "StarterPage",
  data: () => ({
    roles: [],
    loading: true,
    eduOrg: {}
  }),
  components: {
    info,
    op
  },
  mounted(){
    this.$store.state.menuBtn=true
  },
  beforeDestroy(){
    this.$store.state.menuBtn=false
  },
  computed: {
    menu: {
      get() {
        return this.$store.state.Sidebar_drawer;
      },
      set(val) {
        this.$store.commit("SET_SIDEBAR_DRAWER", val);
      },
    },
  },
};
</script>
